@import url('../../../src/styles/tailwind.scss');

$primary-color: #14bf98;
$second-color: #ff0e21;
$third-color: #0c1c1c;

$rgb-primary-color: 44, 193, 255; //--primary-color *
$rgb-second-color: 10, 53, 99; //--second-color *
$rgb-third-color: 35, 96, 147; //--third-color *

@import "../../../src/styles/vars.scss";
@import "../../../src/styles/styles.scss";

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body.webapp-site {
    --font-title: 'Poppins', sans-serif;
    --font-subtext: 'Roboto', sans-serif;
    --font-text: 'Roboto', sans-serif;
	background-color: #f6f9f9;

    .img__bg-nf {
        background-image: url('/assets/img/figure.svg');
    }
    @media screen and (max-width: 767px) {
        .img__bg-nf {
            background-image: url('/assets/img/figure-resp.svg');
        }
    }
}


.layou-prin{
    display: grid;
    width: 100%;
    grid-template-columns: 90%;
    grid-template-columns: 60% 38%;
    gap: 20px;
}

.grid-day {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
    a{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        &:hover{
            background-color: #ffeff7;
            border-color: #c61a72;
        }
    }
    .hover-none:hover{
        background: #c61a72;
        border-color:#c61a72;
    }
}

.btn-cout{
    background-color: #2b2b2b;
    color: white;
    font-weight: 700 !important;
    width: 50px;
    height: 50px;
    transition: 0.2s;
    &:hover{
        background-color: #c61a72;
    }
    &:active{
        scale: 0.9;
    }
}

.grid-ticket{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    gap: 16px;

    a{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        transition: 0.2s;
        &:hover{
            background-color: white;
            border-color: #ffd1e8;
        }
        .item-btns{
            margin: 0 auto;
            width: fit-content;
        }
    }

}

.qr-layout{
    display: grid;
    grid-template-columns: 80% 20%;
    place-content: center;
    gap: 35px;
    max-height: 100%;
    max-width: 95%;
    margin: 0px;
    overflow: hidden;
    height: 100%;
    padding: 60px;
    
    img{
        width: 700px;
        height: 700px;
        object-fit: contain;
        object-position: center;
        margin: 0 auto;

    }
}

.btn-fix{
    position: sticky;
    left: 5%;
    bottom: 2%;
    width: 90%;
    z-index: 99;
    background: #ffffff;
    border: 1px solid #b6b6b6;
}

.btn-qr{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px solid lightgrey;
    border-radius: 11px;
    background-color: white;
    
    span{
        font-size: 35px;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.btn-impri{
    display: flex;
    /* flex-direction: column; */
    gap: 16px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px solid #c61a72;
    border-radius: 11px;
    background-color: #c61a72;
    
    span{
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        color: white;
    }
}

.btn-cash{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: #c61a72;
    border-radius: 12px;
    width: 95px;
    height: 95px;
    transition: scale 0.2s;
    cursor: pointer;
    

    img{
        height: 50px;
    }
    span{
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: 700;
    }

    &:disabled{
        background:rgb(180, 180, 180);
        cursor: not-allowed;
        &:active{
            transform: scale(1);
        }
        
    }
    
    

    &:active{
        transform: scale(0.95);
    }

    
    
}

    
    .loader {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: inline-block;
        border-top: 4px solid #24173D;
        border-right: 4px solid transparent;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
    .loader::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border-bottom: 4px solid #e42f8c;
        border-left: 4px solid transparent;
    }
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

        .success-animation {
            margin: 20px auto;
        }
    
        .checkmark {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            display: block;
            stroke-width: 2;
            stroke: #4bb71b;
            stroke-miterlimit: 10;
            box-shadow: inset 0px 0px 0px #4bb71b;
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
            position: relative;
            top: 5px;
            right: 5px;
            margin: 0 auto;
        }
    
        .checkmark__circle {
            stroke-dasharray: 166;
            stroke-dashoffset: 166;
            stroke-width: 2;
            stroke-miterlimit: 10;
            stroke: #4bb71b;
            fill: #fff;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    
        }
    
        .checkmark__check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
        }
    
        @keyframes stroke {
            100% {
                stroke-dashoffset: 0;
            }
        }
    
        @keyframes scale {
    
            0%,
            100% {
                transform: none;
            }
    
            50% {
                transform: scale3d(1.1, 1.1, 1);
            }
        }
    
        @keyframes fill {
            100% {
                box-shadow: inset 0px 0px 0px 30px #4bb71b;
            }
        }


@media (max-width: 768px) {
    .layou-prin {
        grid-template-columns: 100%;
    }
}